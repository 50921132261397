<template>
  <CCard>
    <CCardHeader>
      {{ $t("user.profile.data.title") }}
      <div class="card-header-actions">
        <CButton
          variant="outline"
          color="primary"
          size="sm"
          :pressed.sync="dataForm.active"
          ><CIcon name="cil-pencil" />&nbsp;{{
            $t("common.editor.button.edit")
          }}</CButton
        >
      </div>
    </CCardHeader>
    <CCardBody>
      <div class="bd-example">
        <dl class="row">
          <dd class="col-sm-2">
            {{ $t("models.user.name") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.first_name }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.user.name_info')"
              horizontal
              autocomplete="name"
              v-model="dataForm.body.first_name"
              :is-valid="'first_name' in dataForm.errors ? false : null"
              :invalid-feedback="
                'first_name' in dataForm.errors
                  ? dataForm.errors.first_name.join('<br>')
                  : null
              "
            />
          </dt>
          <dd class="col-sm-2">
            {{ $t("models.user.surnname") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.last_name }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.user.surnname_info')"
              horizontal
              autocomplete="last_name"
              v-model="dataForm.body.last_name"
              :is-valid="'last_name' in dataForm.errors ? false : null"
              :invalid-feedback="
                'last_name' in dataForm.errors
                  ? dataForm.errors.last_name.join('<br>')
                  : null
              "
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.user.nickname") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.nickname }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.user.nickname_info')"
              horizontal
              autocomplete="last_name"
              v-model="dataForm.body.nickname"
              :is-valid="'nickname' in dataForm.errors ? false : null"
              :invalid-feedback="
                'nickname' in dataForm.errors
                  ? dataForm.errors.nickname.join('<br>')
                  : null
              "
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.user.born") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.born }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.user.born_info')"
              horizontal
              autocomplete="born"
              type="date"
              v-model="dataForm.body.born"
              :is-valid="'born' in dataForm.errors ? false : null"
              :invalid-feedback="
                'born' in dataForm.errors
                  ? dataForm.errors.born.join('<br>')
                  : null
              "
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.user.phone") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.phone }}</span>
            <CInput
              v-if="dataForm.active"
              :description="$t('models.user.phone_info')"
              horizontal
              autocomplete="phone"
              v-model="dataForm.body.phone"
              :is-valid="'phone' in dataForm.errors ? false : null"
              :invalid-feedback="
                'phone' in dataForm.errors
                  ? dataForm.errors.phone.join('<br>')
                  : null
              "
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.user.lang") }}
          </dd>
          <dt class="col-sm-4">
            <span v-if="!dataForm.active">{{ dataForm.body.language }}</span>
            <CSelect
              v-if="dataForm.active"
              :description="$t('user.profile.data.lang_info')"
              horizontal
              :is-valid="'language' in dataForm.errors ? false : null"
              :invalid-feedback="
                'language' in dataForm.errors
                  ? dataForm.errors.language.join('<br>')
                  : null
              "
              :value.sync="dataForm.body.language"
              :options="options"
            />
          </dt>

          <dd class="col-sm-2">
            {{ $t("models.user.defaultmail") }}
          </dd>
          <dt class="col-sm-4">{{ user.email }}</dt>

          <dd class="col-sm-2">
            {{ $t("models.user.dateregistered") }}
          </dd>
          <dt class="col-sm-4">
            {{ $d(new Date(user.date_joined), "long") }}
          </dt>
        </dl>
      </div>
    </CCardBody>
    <CCardFooter v-if="dataForm.active">
      <vue-ladda
        :loading="dataForm.loading"
        data-style="zoom-in"
        button-class="btn btn-primary btn-sm"
        @click.prevent="SaveData"
        ><CIcon name="cil-save" />&nbsp;{{
          $t("common.editor.button.save")
        }}</vue-ladda
      >
    </CCardFooter>
  </CCard>
</template>

<script>
import { DataManager } from "@syncfusion/ej2-data";
import DrfAdaptor2 from "../../http/EJ2_adapter2";
import { apiUrl } from "../../http";

let dm = new DataManager({
  url: apiUrl(`/api/account/user/`),
  adaptor: new DrfAdaptor2(),
  crossDomain: true,
});
export default {
  name: "ProfileData",
  data() {
    return {
      dataForm: {
        loading: false,
        active: false,
        body: {
          first_name: "",
          last_name: "",
          nickname: "",
          born: "",
          phone: "",
          language: "",
        },
        errors: {},
      },
    };
  },
  mounted() {
    this.dataForm.body.first_name = this.user.first_name;
    this.dataForm.body.last_name = this.user.last_name;
    this.dataForm.body.nickname = this.user.nickname;
    this.dataForm.body.born = this.user.born;
    this.dataForm.body.phone = this.user.phone;
    this.dataForm.body.language = this.user.language;
  },
  computed: {
    user() {
      // console.log(this.$auth.user());
      return this.$auth.user();
    },
    options() {
      return Object.keys(this.$i18n.messages);
    },
  },
  methods: {
    SaveData() {
      // console.log("save_data")
      // console.log(this.dataForm.body)
      this.dataForm.loading = true;
      this.dataForm.errors = {};
      dm.update("", this.dataForm.body).then(
        (e) => {
          // console.log("after update");
          // console.log(e);
          this.$auth.user(e);
          this.dataForm.loading = false;
          this.dataForm.active = false;
          this.$store.dispatch("toaster/add", {
            title: this.$t("user.profile.data.ok.title"),
            text: this.$t("user.profile.data.ok.message"),
            autohide: true,
          });
        },
        (reason) => {
          let errors = JSON.parse(reason[0].error.response);
          this.dataForm.loading = false;
          this.dataForm.errors = errors;
        }
      );
    },
  },
};
</script>
